:root {
  --animation_speed: 12s;
  --transforn_fst_img: translatex(0) scale(1.2);
  --transforn_snd_img: translatex(-110%) rotateY(-20deg) scale(0.7);
  --transforn_trd_img: translatex(0) scale(0.7);
  --transforn_fth_img: translatex(110%) rotateY(20deg) scale(0.7);
  --opacity_fst_img: 1;
  --opacity_snd_img: 0.7;
  --opacity_trd_img: 0;
  --opacity_fth_img: 0.9;
  --zindex_fst_img: 2;
  --zindex_snd_img: 0;
  --zindex_trd_img: 0;
  --zindex_fth_img: 1;
}

* {
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
}

header {
  width: 100%;
  background-image: url(./Assets/Rectangle\ 302.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
}
header img {
  width: 40%;
  margin: 4.69% auto;
}
header div:nth-child(1) {
  display: flex;
  align-items: flex-start;
}
.button_wraper a {
  text-decoration: none;
  background-color: white;
  padding: 0.5em 1.1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #f44336;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-left: 1em;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: #ef5b50;
}
.button_container-desktop {
  display: flex;
}
.button_wraper {
  margin: -20px auto;
}

.button_container-mobile {
  display: none;
}
.content_wraper {
  margin: 1% 28%;
  text-align: center;
}

.content_wraper_large {
  margin: 1% 16% !important;
}
.jc-landing-page-heading {
  font-family: 'Bitter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  margin-top: 1.7em;
}
.jc-landing-page-paragraph {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1em;
}
.jc-red {
  color: #ef5b50;
}

.jc-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  padding: 0 1.5rem;
  color: #ffffff;
  background-color: #ef5b50;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0.8px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  border: none;
  user-select: none;
  transition: all 0.3s ease-out;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.jc-btn:hover {
  text-decoration: none;
  box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.225);
}

.jc-input {
  font-family: 'Raleway';
  font-size: 1rem;
  width: 50%;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: #262626;
  font-weight: 600;
  transition: 0.1s ease-out;
}
.jc-input:focus {
  border-color: #ef5b50;
}
.jc-input:focus + label {
  color: #262626;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.jc-input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.jc-error {
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: 600;
}
.jc-bodycopy {
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.crousal-wraper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  /* padding: 10px 30px 10px; */
  /* margin-bottom: 20px; */
}
.crousal-container {
  width: 100%;
  transform-style: preserve-3d;
  display: block;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cards {
  position: relative;
  width: 50%;
  height: 100%;
  margin-bottom: 20px;
  perspective: 1000px;
}
.card {
  position: absolute;
  width: 55%;
  height: 70%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}
.crousal-image {
  width: 100%;
  /* height: 16em; */
  border-radius: 10px;
  object-fit: fill;
}

#img-1 {
  transform: translatex(-110%) rotateY(-20deg) scale(0.7);
  opacity: 0.9;
  z-index: 0;
  animation: slider_2 var(--animation_speed) infinite forwards linear;
}

#img-2 {
  transform: translatex(110%) rotateY(20deg) scale(0.7);
  opacity: 0.9;
  z-index: 0;
  animation: slider_3 var(--animation_speed) infinite forwards linear;
}

#img-3 {
  transform: translatex(0) scale(1.2);
  opacity: 1;
  z-index: 1;
  animation: slider var(--animation_speed) infinite forwards linear;
}
#img-4 {
  transform: translatex(0%) scale(0.7);
  opacity: 0;
  z-index: 0;
  animation: slider_4 var(--animation_speed) infinite forwards linear;
}

@keyframes slider {
  0% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  11.11% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  22.22% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  33.33% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  44.44% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  55.55% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  66.66% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  77.77% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  88.88% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  100% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
}

@keyframes slider_2 {
  0% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  11.11% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  22.22% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  33.33% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  44.44% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  55.55% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  66.66% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  77.77% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  88.88% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  100% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
}

@keyframes slider_3 {
  0% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  11.11% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  22.22% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  33.33% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  44.44% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  55.55% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  66.66% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  77.77% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  88.88% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  100% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
}

@keyframes slider_4 {
  0% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  11.11% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  22.22% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  33.33% {
    transform: var(--transforn_fst_img);
    opacity: var(--opacity_fst_img);
    z-index: var(--zindex_fst_img);
  }
  44.44% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  55.55% {
    transform: var(--transforn_snd_img);
    opacity: var(--opacity_snd_img);
    z-index: var(--zindex_snd_img);
  }
  66.66% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  77.77% {
    transform: var(--transforn_trd_img);
    opacity: var(--opacity_trd_img);
    z-index: var(--zindex_trd_img);
  }
  88.88% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
  100% {
    transform: var(--transforn_fth_img);
    opacity: var(--opacity_fth_img);
    z-index: var(--zindex_fth_img);
  }
}

footer {
  /* position: fixed;
  left: 0;
  bottom: 0; */
  width: 100%;
  text-align: center;
  background-color: white;
  z-index: 5;
}
hr {
  margin-bottom: 1em;
  width: 60%;
  background: #e1e1e1;
}
.footer-copyright {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 1em;
}
.mobile-image {
  display: none;
}
.hide-mobile {
  display: none;
}

.lightbox-custom > div {
  z-index: 9999;
}

.sticky {
  position: fixed;
  top: -3px;
  width: 100%;
  z-index: 1;
}

.sticky + .content {
  padding-top: 15%;
}
.herobutton + .content {
  padding-top: 15%;
}

.owl-item .item {
  transform: translate3d(
    0,
    0,
    0
  ); /* DO NOT REMEMBER WHERE TU PUT THIS, SEARCH FOR 3D ACCELERATION */
  margin: 50px 0; /* OVERWRITE PLUGIN MARGIN */
}

.owl-item img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transition: all 0.5s ease-in;
  transform: scale(0.7);
}

.center img {
  margin-top: 30px;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.5s ease-out;
  z-index: 9999;
  opacity: 1 !important;
}
.owl-nav {
  text-align: center;
}
.owl-item img {
  margin-top: 60px;
  opacity: 0.9;
}

.owl-nav button {
  font-size: 24px !important;
  margin: 10px;
  color: #033aff !important;
}
.owl-item .slide-title,
.owl-item svg {
  visibility: hidden;
}
.center .slide-title {
  visibility: visible !important;
  display: flex;
  margin-top: 18px;
  width: calc(100% + 60px);
  margin-left: -40px;
}
.slider-heading {
  display: inline-block;
  color: #ef5b50;
  text-align: left;
}
.slider-info {
  display: inline-block;
  margin-left: 20px;
  text-align: left;
}
.center svg {
  visibility: visible;
  cursor: pointer;
  position: absolute;
  top: 40%;
  left: 43%;
  margin: auto;
}
.owl-carousel .owl-dots.disabled {
  display: block !important;
}
.owl-carousel .owl-dots.disabled {
  text-align: center;
  padding-top: 15px;
  margin-top: -70px;
  margin-bottom: 30px;
}

@media screen and (max-width: 820px) {
  .content_wraper {
    margin: 1% 25%;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  header img {
    width: 55%;
    margin: 3.5% auto;
    object-fit: contain;
    height: auto;
  }
  .sticky {
    top: 0;
  }
  .button_container-desktop {
    display: none;
  }

  .owl-item img {
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
  .center img {
    margin-top: 30px;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  .center svg {
    width: 30px;
    height: 30px;
    top: 40%;
    left: 45%;
  }
  .center .slide-title {
    visibility: visible !important;
    flex-direction: column;
    margin-top: -15px;
    width: 100%;
    margin-left: 0px;
  }
  .content_wraper,
  .content_wraper-large {
    margin: 0 14% !important;
  }
  .jc-landing-page-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-top: 1.5em;
  }

  .jc-landing-page-paragraph {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .center .slider-heading,
  .center .slider-info {
    text-align: center;
  }
  .slider-heading{
    text-transform: uppercase;
  }
  .slider-info {
    margin-top: 8px;
    margin-left: 0px;
    padding: 0px 8%;
  }
  .button_container-mobile {
    margin-top: 1em;
    display: flex;
  }
  .button_wraper a {
    text-decoration: none;
    background-color: #ef5b50;
    padding: 5px 7px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ef5b50;
    box-shadow: unset;
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .button_wraper {
    margin: 0px auto;
  }
  .mobile-image {
    display: flex;
    align-items: flex-start;
  }
  .mobile-image img {
    width: 90%;
    margin: 0 auto;
    object-fit: contain;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0.5em;
    width: 60%;
  }
  .footer-copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 1em;
  }
  .jc-bodycopy {
    font-size: 12px;
    line-height: 18px;
  }
  .jc-input {
    width: 100%;
    height: 29px;
    font-size: 12px;
  }
  .jc-btn {
    height: 29px;
    line-height: 24px;
    padding: 0 12px;
    font-size: 12px;
  }
  .hide-desktop {
    display: none;
  }
  .hide-mobile {
    display: block;
  }
}

.owl-nav button {
  position: absolute;
  top: 40%;
  background-color: #000;
  color: #838383 !important;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.owl-nav button.owl-prev {
  left: 6px;
}
.owl-nav button.owl-next {
  right: 6px;
}

.owl-dots {
  text-align: center;
  padding-top: 15px;
  margin-top: -105px;
  margin-bottom: 30px;
}
.owl-dots button.owl-dot {
  width: 10px;
  height: 10px;
  cursor: pointer !important;
  border-radius: 50%;
  display: inline-block;
  background-color: #838383 !important;
  margin: 0 3px;
}
.owl-dots button.owl-dot.active {
  background-color: #ef5b50 !important;
}

.owl-dots button.owl-dot:focus {
  outline: none;
}

.owl-nav button:focus {
  outline: none;
}
